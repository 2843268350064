import React, { useState } from "react";
const re = /^[A-zÀ-ú\s|´`]+$/;

interface NameInputType {
  onNameChanged: (name: any) => void;
  placeholder?: string;
  helperText?: string;
}

const NameInput: React.FC<NameInputType> = ({
  onNameChanged,
  helperText,
  placeholder = "Nombre",
}) => {
  const [name, setName] = useState("");

  const onChange = (value) => {
    if (value === "" || re.test(value)) {
      setName(value);
      onNameChanged(value);
    }
  };

  return (
    <>
      <input
        type="text"
        value={name}
        onChange={({ target: { value } }) => onChange(value)}
        name="name"
        id="name"
        placeholder={placeholder}
        maxLength={40}
        required
      />
      {helperText && <span className="helper-text">{helperText}</span>}
    </>
  );
};

export default NameInput;
