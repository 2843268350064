import React, { useEffect } from "react";
import "./intro.scss";

const IntroContact: React.FC = () => {
  useEffect(() => {
    let intervalId;
    let count = 0;
    // const introDuration = 10;

    if (typeof window !== `undefined`) {
      const hellos = document.getElementsByClassName("hello");

      const body = document.body;
      body.classList.add("overflow-hidden");

      intervalId = setInterval(() => {
        if (count >= 1 && count < 5) {
          clearColoredClass(hellos);

          if (count === 1) {
            addColorClass(hellos, 6);
          }
          if (count === 2) {
            addColorClass(hellos, 7);
          }
          if (count === 3) {
            addColorClass(hellos, 5);
          }
          if (count === 4) {
            addColorClass(hellos, 6);
          }
          // if (count === 1) {
          //   addColorClass(hellos, 8);
          // }
          // if (count === 2) {
          //   addColorClass(hellos, 9);
          // }
          // if (count === 3) {
          //   addColorClass(hellos, 6);
          // }
          // if (count === 5) {
          //   addColorClass(hellos, 6);
          // }

          // if (count === 1) {
          //   addColorClass(hellos, 6);
          // }
          // if (count === 2) {
          //   addColorClass(hellos, 4);
          // }
          // if (count === 3) {
          //   addColorClass(hellos, 7);
          // }
          // if (count === 4) {
          //   addColorClass(hellos, 3);
          // }
          // if (count === 5) {
          //   addColorClass(hellos, 7);
          // }
          // if (count === 9) {
          //   addColorClass(hellos, 5);
          // }
        }

        if (count >= 5) {
          clearInterval(intervalId);
          removeBodyOverflow();
        }

        count++;
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const closeIntro = () => {
    if (typeof window !== `undefined`) {
      const introContact = document.getElementById("intro-contact");
      introContact.classList.add("closed");
      removeBodyOverflow();
    }
  };

  const removeBodyOverflow = () => {
    if (typeof window !== `undefined`) {
      const body = document.body;
      body.classList.remove("overflow-hidden");
    }
  };

  const clearColoredClass = (elements) => {
    for (let index = 0; index < elements.length; index++) {
      const hello = elements[index];
      hello.classList.remove("colored");
    }
  };

  const addColorClass = (elements, i) => {
    elements[i].classList.add("colored");
  };

  return (
    <div className="intro-contact animate" id="intro-contact">
      <div className="close-icon" onClick={() => closeIntro()}>
        <img src="../close-icon.svg" />
      </div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black colored">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
      <div className="hello main text-uppercase font-black">Hola</div>
    </div>
  );
};

export default IntroContact;
