import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import { contactService } from "../../../services/contact";
import EmailInput from "../FormComponents/EmailInput";
import HourSelect from "../FormComponents/HourSelect";
import NameInput from "../FormComponents/NameInput";
import PhoneInput from "../FormComponents/PhoneInput";
import TextArea from "../FormComponents/TextArea";
import "./mainContact.scss";

const MainContact: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<number>();
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [hour, setHour] = useState<string>("");
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const sendEmail = (event) => {
    event.preventDefault();
    if (!hour) return;

    setFormLoading(true);

    contactService
      .sendEmail({
        phone,
        name,
        message,
        email,
        hour,
      })
      .then(() => {
        navigate("/exito");
      })
      .catch(() => {}); // TODO: handler error
  };

  return (
    <div className="main-contact">
      <div className="container">
        <div className="cocreemos">
          <img src="../codevelopers-CO-white.svg" loading="lazy" />
        </div>
        <div className="form-content">
          <div className="form-title font-black">
            Por favor, dejanos tus datos
            <br />
            para ponernos en contacto con vos
          </div>

          <form onSubmit={(e) => sendEmail(e)}>
            <div className="form-row">
              <NameInput onNameChanged={(name) => setName(name)} />
              <PhoneInput onPhoneChanged={(phone) => setPhone(phone)} />
            </div>
            <div className="form-row">
              <EmailInput onEmailChanged={(email) => setEmail(email)} />
              <HourSelect onHourChanged={(hour) => setHour(hour)} />
            </div>

            <TextArea
              placeholder="¿Algo que quieras agregar?"
              rows={3}
              onChanged={(text) => setMessage(text)}
              required={true}
            />

            <button
              type="submit"
              disabled={formLoading || !name || (!email && !phone) || !hour}
            >
              {formLoading ? "ENVIANDO..." : "ENVIAR"}
            </button>
          </form>
        </div>

        <Link className="logo" to="/">
          <img src="../codevelopers-logo-white.svg" />
        </Link>
      </div>
    </div>
  );
};

export default MainContact;
