import React, { useState } from "react";
const re = /^[0-9\b]+$/;

interface PhoneInputType {
  onPhoneChanged: (phone: any) => void;
  helperText?: string;
  placeholder?: string;
}

const PhoneInput: React.FC<PhoneInputType> = ({
  onPhoneChanged,
  placeholder = "Teléfono",
  helperText,
}) => {
  const [phone, setPhone] = useState("");

  const onChange = (value) => {
    if (value === "" || re.test(value)) {
      setPhone(value);
      onPhoneChanged(value);
    }
  };

  return (
    <>
      <input
        type="tel"
        value={phone}
        onChange={({ target: { value } }) => onChange(value)}
        name="phone"
        id="phone"
        placeholder={placeholder}
        required
      />
      {helperText && <span className="helper-text">{helperText}</span>}
    </>
  );
};

export default PhoneInput;
