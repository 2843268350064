import React, { useEffect, useState } from "react";
import "./projectFormCanje.scss";

interface ProjectFormCanjeProps {
  title: string;
  subtitle: string;
  isValid: (isValid: boolean) => void;
  formValues: (values) => void;
}

const ProjectFormCanje: React.FC<ProjectFormCanjeProps> = ({
  title,
  subtitle,
  isValid,
  formValues,
}) => {
  const [type, setType] = useState<string>("cuotasEfectivo");
  const [canjeType, setCanjeType] = useState<string>("");
  const [valorAproximado, setValorAproximado] = useState<string>("");

  useEffect(() => {
    validateForm(type, canjeType, valorAproximado);
  }, [type, canjeType, valorAproximado]);

  const validateForm = (
    type: string,
    canjeType: string,
    valorAproximado: string
  ): void => {
    if (type === "cuotasEfectivo") {
      isValid(true);
      formValues({
        type,
      });
      return;
    }

    if (type === "canje" && canjeType && valorAproximado) {
      isValid(true);

      formValues({
        type,
        canjeType,
        valorAproximado,
      });
      return;
    }

    isValid(false);
  };

  return (
    <div className="project-form-canje">
      <div className="title-content">
        <h1 className="form-title font-black">{title}</h1>
        <p className="text-center">{subtitle}</p>
      </div>
      <div className="radio-content">
        <input
          className="big-radio"
          type="radio"
          id="radio1"
          name="radios"
          value="cuotasEfectivo"
          defaultChecked={type === "cuotasEfectivo"}
          onChange={({ target: { value } }) => {
            setType(value);
            setValorAproximado("");
            setCanjeType("");
          }}
        />
        <label htmlFor="radio1">CUOTAS / EFECTIVO</label>
        <input
          className="big-radio"
          type="radio"
          id="radio2"
          name="radios"
          value="canje"
          defaultChecked={type === "canje"}
          onChange={({ target: { value } }) => setType(value)}
        />
        <label htmlFor="radio2">CANJE</label>
      </div>

      <div className="radio-messages-content">
        {type === "cuotasEfectivo" && (
          <div className="">
            <p className="font-black">
              FINANCIACION {">>"} Suites financiadas hasta en 60 cuotas.
            </p>
            <div className="desc" />
            <p className="font-black">
              EFECTIVO {">>"} Hasta un 10% de descuento por pago en efectivo.
            </p>
            <div className="desc">
              Un texto que explica acerca de esta opción de canje. Un breve
              párrafo explicativo.
              <br /> Para contar los beneficios y la novedad de esta opción.
            </div>
          </div>
        )}
        {type === "canje" && (
          <div className="">
            <div className="desc">
              Un texto que explica acerca de esta opción de canje. Un breve
              párrafo explicativo.
              <br /> Para contar los beneficios y la novedad de esta opción.
            </div>

            <div className="radio-canje-content">
              <div>
                <input
                  type="radio"
                  id="radio3"
                  name="radiosCanje"
                  value="Casa"
                  defaultChecked={canjeType === "Casa"}
                  onChange={({ target: { value } }) => setCanjeType(value)}
                />
                <label htmlFor="radio3" className="font-bold">
                  CASA
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id="radio4"
                  name="radiosCanje"
                  value="Depto"
                  defaultChecked={canjeType === "Depto"}
                  onChange={({ target: { value } }) => setCanjeType(value)}
                />
                <label htmlFor="radio4" className="font-bold">
                  DEPTO
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id="radio5"
                  name="radiosCanje"
                  value="Terreno"
                  defaultChecked={canjeType === "Terreno"}
                  onChange={({ target: { value } }) => setCanjeType(value)}
                />
                <label htmlFor="radio5" className="font-bold">
                  TERRENO
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id="radio6"
                  name="radiosCanje"
                  value="Otro"
                  defaultChecked={canjeType === "Otro"}
                  onChange={({ target: { value } }) => setCanjeType(value)}
                />
                <label htmlFor="radio6" className="font-bold">
                  OTRO
                </label>
              </div>
            </div>

            <select
              style={{ backgroundImage: "url(../down.svg)" }}
              onChange={({ target: { value } }) => setValorAproximado(value)}
              required
              defaultValue=""
            >
              <option disabled value="">
                Valor aproximado
              </option>
              <option value="menos de 100.000 USD">Menos de 100.000 USD</option>
              <option value="más de 100.000 USD">Más de 100.000 USD</option>
              <option value="más de 200.000 USD">Más de 200.000 USD</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectFormCanje;
