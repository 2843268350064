import React, { useState } from "react";

interface EmailInputType {
  onEmailChanged: (name: any) => void;
  helperText?: string;
  placeholder?: string;
}

const EmailInput: React.FC<EmailInputType> = ({
  onEmailChanged,
  helperText,
  placeholder = "E-mail",
}) => {
  const [email, setEmail] = useState<string>("");

  const onChange = (value) => {
    setEmail(value);
    onEmailChanged(value);
  };

  return (
    <>
      <input
        type="email"
        value={email}
        onChange={({ target: { value } }) => onChange(value)}
        name="email"
        id="email"
        placeholder={placeholder}
        maxLength={60}
        required
      />
      {helperText && <span className="helper-text">{helperText}</span>}
    </>
  );
};

export default EmailInput;
