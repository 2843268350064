import { navigate } from "gatsby";
import React, { useState } from "react";
import { contactService } from "../../../services/contact";
import EmailInput from "../FormComponents/EmailInput";
import HourSelect from "../FormComponents/HourSelect";
import NameInput from "../FormComponents/NameInput";
import PhoneInput from "../FormComponents/PhoneInput";
import ProjectFormCanje from "../ProjectFormCanje";
import "./projectContact.scss";

interface ProjectContactProps {
  projectName: string;
  logo: string;
  steps: number;
  step2Data?: any;
}

const ProjectContact: React.FC<ProjectContactProps> = ({
  projectName,
  logo,
  steps,
  step2Data,
}) => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<number>();
  const [email, setEmail] = useState<string>("");
  const [hour, setHour] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [canjeFormValues, setCangeFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isSubformValid, setIsSubformValid] = useState<boolean>(false);

  const sendEmail = (event) => {
    event.preventDefault();
    if (!hour) return;

    setFormLoading(true);

    contactService
      .sendProjectEmail({
        projectName,
        phone,
        name,
        email,
        hour,
        ...canjeFormValues,
      })
      .then(() => {
        navigate("/exito");
      })
      .catch(() => {});
  };

  const nextPage = (event): void => {
    event.preventDefault();
    setPage(page + 1);
  };

  const prevPage = (): void => {
    setPage(page - 1);
  };

  const isValid = (valid: boolean): void => {
    setIsSubformValid(valid);
  };

  const renderFormComponent = (stepData) => {
    let componentToRender = <React.Fragment />;

    switch (stepData.component) {
      case "canje":
        componentToRender = (
          <ProjectFormCanje
            isValid={(valid) => isValid(valid)}
            formValues={(values) => setCangeFormValues(values)}
            title={stepData.title}
            subtitle={stepData.subtitle}
          />
        );
        break;
    }

    return componentToRender;
  };

  return (
    <div className="project-contact">
      <div className="container">
        <div className="form-content">
          <div
            className="project-logo"
            dangerouslySetInnerHTML={{ __html: logo }}
          />

          <div className="components-wraper" id="components-wraper">
            <div
              className={steps === 1 || page === 1 ? "visible" : "invisible"}
            >
              <div className="title-content">
                <h1 className="form-title font-black">
                  ¿QUERES CONOCER MÁS DE ESTA PROPUESTA?
                </h1>
                <p className="text-center">
                  Dejanos tus datos y a la brevedad te contactamos
                </p>
              </div>

              <form>
                <div className="form-fields">
                  <NameInput onNameChanged={(name) => setName(name)} />
                  <PhoneInput onPhoneChanged={(phone) => setPhone(phone)} />
                  <EmailInput onEmailChanged={(email) => setEmail(email)} />
                  <HourSelect onHourChanged={(hour) => setHour(hour)} />
                </div>
              </form>
            </div>

            {/* STEP 2 */}
            {step2Data && step2Data.component ? (
              <div
                className={steps === 2 && page === 2 ? "visible" : "invisible"}
              >
                {renderFormComponent(step2Data)}
              </div>
            ) : null}
            {/* END STEP 2 */}
          </div>

          {page === steps ? (
            <button
              type="button"
              onClick={sendEmail}
              disabled={
                formLoading ||
                !name ||
                (!email && !phone) ||
                !hour ||
                (page > 1 && !isSubformValid)
              }
            >
              {formLoading ? "ENVIANDO..." : "ENVIAR"}
            </button>
          ) : (
            <button
              type="button"
              onClick={nextPage}
              disabled={formLoading || !name || (!email && !phone) || !hour}
            >
              CONTINUAR
            </button>
          )}
        </div>

        {steps && steps > 1 ? (
          <div className="steps-control-wrapper">
            <div className="steps-back">
              {page > 1 && (
                <div className="font-bold" onClick={prevPage}>
                  ← Atrás
                </div>
              )}
            </div>
            <div className="steps font-bold">
              <div>
                {page} / {steps}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectContact;
