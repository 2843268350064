import React, { useState } from "react";

interface HourSelectProps {
  onHourChanged: (name: string) => void;
}

const HourSelect: React.FC<HourSelectProps> = ({ onHourChanged }) => {
  const [hour, setHour] = useState("");

  const onChange = (value) => {
    setHour(value);
    onHourChanged(value);
  };

  return (
    <select
      style={{ backgroundImage: "url(../down.svg)" }}
      onChange={({ target: { value } }) => onChange(value)}
      required
      defaultValue=""
    >
      <option disabled value="">
        Horario de contacto
      </option>
      <option value="9 a 12 hs.">9 a 12 hs.</option>
      <option value="12 a 14 hs.">12 a 14 hs.</option>
      <option value="14 a 18 hs.">14 a 18 hs.</option>
      <option value="18 a 20 hs.">18 a 20 hs.</option>
    </select>
  );
};

export default HourSelect;
