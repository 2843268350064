import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { projectsForm } from "./forms-data";
import IntroContact from "./Intro";
import MainContact from "./MainContact";
import ProjectContact from "./ProjectContact";
import "./contact.scss";

const ContactPage: React.FC = () => {
  const [proyecto, setProyecto] = useState("");

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const proyecto = params.get("proyecto");
      if (proyecto && projectsForm[proyecto]) {
        setProyecto(proyecto);
      }
    }
  }, []);

  const renderForm = () => {
    if (proyecto) {
      const data = projectsForm[proyecto];
      return (
        <ProjectContact
          projectName={data.projectName}
          steps={data.steps}
          logo={data.logo}
          step2Data={data.step2Data}
        />
      );
    }

    return <MainContact />;
  };

  return (
    <div className="contact-page">
      <IntroContact />
      <Link className="close-icon" to={`/${proyecto}`}>
        <img src="../close-icon.svg" />
      </Link>

      {renderForm()}
    </div>
  );
};

export default ContactPage;
